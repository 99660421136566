<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>{{ headerTitle }}</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" title="닫기" @click="popupClose"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="80px">
          <col>
          <col width="80px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th><label>작성자</label></th>
            <td><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxUserV_show" disabled></v-text-field></td>
            <!-- <th><label>게시기간</label></th>
            <td style="width: 270px;">
              <div class="jh-cols">                        
                <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
                </v-menu>
                <span class="jh-unit">~</span>                       
                <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
                </v-menu>
              </div>
            </td> -->
            <!-- <th><label>고정유무</label></th>
            <td><v-checkbox class="jh-check" v-model="fixedFlag" @click="fixedClick" /></td> -->
          </tr>
          <tr>
            <th><label class="is-required">제목</label></th>
            <td colspan="5"><v-text-field type="text" class="jh-form" v-model="txtBoxTitleV"></v-text-field></td>
          </tr>
        </tbody>
      </table>
      <div class="jh-editor is-mt-5"><ckeditor v-model="txtAreaV" :config="editorConfig"></ckeditor></div>
      
      <div class="jh-ui-header is-mt-10">
        <h2>첨부파일</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-border-blue" @click="clickAddFiles" >파일등록</v-btn>
          <v-btn class="jh-btn is-del" @click="clickDelFiles" >파일삭제</v-btn>
        </div>
      </div>
      <v-data-table
        class="jh-grid has-control"
        height="116"
        :headers="gridDataHeaders"
        :items="gridDataText"
        v-model="gridListSelected"
        item-key="index"
        fixed-header
        hide-default-footer
        calculate-widths
        show-select
        :itemsPerPage="-1"
      >
        <template v-slot:item.DOWNLOAD = {item}>
          <div>
            <v-btn class="jh-btn is-sm is-fill" @click="fileDownload(item)">다운로드</v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="jh-dialog-footer" >
      <v-btn class="jh-btn is-md is-main" @click="clickBtnSave">저장</v-btn>
    </div>

    <!-- 파일 업로드 모달창 -->
    <v-dialog persistent v-model="dialogVueDropzone" v-if="dialogVueDropzone">
      <dialog-VueDropzone
        headerTitle="첨부파일 등록"
        :ASP_NEWCUST_KEY = this.dropZoneParam.ASP_NEWCUST_KEY
        :fileGroupKey    = this.dropZoneParam.fileGroupKey
        :pathType        = this.dropZoneParam.pathType
        :busiType        = this.dropZoneParam.busiType
        :acceptedFiles   = this.dropZoneParam.acceptedFiles
        :userOptions     = this.dropZoneParam.userOptions
        @hide="hideDialog('VueDropzone')"
        @submit="uploadFileResponse"
      >
      </dialog-VueDropzone>
    </v-dialog>
    <!-- 파일 업로드 모달창 -->
  </div>
</template>

<script>
  //import Ckeditor from "@/components/Ckeditor";
  import {mixin} from "../../mixin/mixin";
  import vue2Dropzone from "vue2-dropzone";
  import { mapState, mapGetters, mapActions } from "vuex"; 
  import DialogVueDropzone from "@/components/VueDropzone";
  import DialogFileUploadPop from "@/components/FileUploadPop";

  export default {
    name: "MENU_E050202P01", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
      headerTitle: {
        type: String,
        default: "제목",
      },
    },
    components: {
      vueDropzone: vue2Dropzone,
      DialogFileUploadPop,
      DialogVueDropzone,
    //  Ckeditor,
    },
    mixins:[mixin],

    data: () => ({
      brdNo       : '',
      brdId       : '',
      brdRmkNo    : '',
      txtBoxBizV  : '',
      txtBoxUserV : '',
      txtBoxUserV_show: '',
      txtBoxTitleV: '',
      txtAreaV    : '',
      fixedFlag : false,

      flagData    : {
        inputFlag : '',
        replyYn   : '',
        lvlNo     : '',
        upperBrdNo: '',
      },

      editorConfig: {
        extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
        width: "100%",
        height: "300px",
        toolbar: [
          { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
          { name: "editing" }, //, items: [ "Scayt" ]
          { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
          { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
          { name: "forms" },
          { name: "styles", items: [ "Font", "FontSize" ] },
          { name: "styles", items: [ "Styles", "Format" ] },
          { name: "others", items: [ "-" ] },
          "/",
          { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
          { name: "basicstyles", items: [ "Bold", "Italic", "Strike", "-", "RemoveFormat" ] },
          { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
          { name: "document", items: [ "Source" ] },
          { name: "tools", items: [ "Maximize" ] },
          { name: "about", items: [] }
        ]
      },

      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
      ],

      menuStartDate: false,
      menuEndDate: false,
      footerHideTitle: "닫기",

      // 첨부파일 그리드 헤더
      gridDataHeaders: [
        { text: "No",       value: "index",   width: "50px",  align: "center", sortable: false,},
        { text: "파일명",    value: "ORIGINAL_FILENAME",         align: "center",  sortable: false,},
        { text: "크기",      value: "FILE_SIZE", width: "80px", align: "center", sortable: false,},
        { text: "다운로드",   value: "DOWNLOAD", width: "70px", align: "center", sortable: false,},
        { text: "다운로드수", value: "DNLOD_CNT", width: "80px", align: "center", sortable: false,},
      ],

      gridDataText: [], //첨부파일 리스트
      gridListSelected: [], //파일업로드 팝업 변수

      // 첨부파일 팝업 param, boolean
      dropZoneParam: {},
      dialogVueDropzone: false,

      // etc
      ASP_NEWCUST_KEY: "001",
      FILE_GROUP_KEY : "",

      BRD_QKTY:[],
      BRD_NEWQKTY: '1',

      alertMsg: {
        selBoxBizChk : '회사구분 은(는) 필수입력입니다.',
        noCntData    : '조회된 데이터가 없습니다.',
        newWriteChk  : '조회 후 작성 가능합니다.',
        delChk       : '현 게시물을 삭제 할 경우 댓글 및 댓글까 전부 조회되지 않습니다. 계속 진행하시겠습니까?',
        selBoxIdChk  : '게시판명 은(는) 필수입력입니다.',
        responseError: '시스템에 오류가 발생하였습니다.',
      },
    }),
    methods: {
      fixedClick() {
        //console.log(`TEST : ${this.fixedFlag}`)
      },

      //***************************************
      //             게시글 저장
      //***************************************
      // 저장 버튼 clickEvent
      clickBtnSave() {
        if(this.txtBoxTitleV=='' || this.txtBoxTitleV==null) {
          this.common_alert("제목 은(는) 필수입력입니다.", "error");
          return;
        }
        this.getBrdRmkNo();

      },

      // 데이터 저장
      setSaveData() {
        let requestData = {
          headers: {},
          sendData: {}
        }

        requestData.headers["URL"]       = "/api/board/common/ntt-atchmnfl/upload";
        requestData.headers["SERVICE"]   = "board.common.ntt-atchmnfl";
        requestData.headers["METHOD"]    = "upload";
        requestData.headers["ASYNC"]     = "true";
        requestData.headers["CALL_BACK"] = "BoardProcessPopup.processRtn_callback";
        requestData.headers["TYPE"]      = "BIZ_SERVICE";

        requestData.sendData["ASP_NEWCUST_KEY"] = "001";
        // requestData.sendData["BULTN_FR_DT"]     = this.dates[0];
        // requestData.sendData["BULTN_TO_DT"]     = this.dates[1];
        requestData.sendData["FST_USER_NM"]     = this.txtBoxUserV;
        requestData.sendData["BRD_TIT"]         = this.restoreXSS_CKeditorDec(this.txtBoxTitleV);
        requestData.sendData["BRD_RMK"]         = this.restoreXSS_CKeditorDec(this.txtAreaV);
        //고정 게시판 추가 일반 : 6, 고정 : 60
        requestData.sendData["NEW_BRD_ID"]          = (this.fixedFlag)? "60" : "2";
        requestData.sendData["BRD_ID"]          = (this.brdId != '' && this.brdId != null)? this.brdId : "2";
        requestData.sendData["BRD_NO"]          = this.brdNo;
        requestData.sendData["UPPER_BRD_NO"]    = this.flagData.upperBrdNo;
        requestData.sendData["LVL_NO"]          = this.flagData.lvlNo;
        requestData.sendData["REPLE_YN"]        = this.flagData.replyYn;
        requestData.sendData["USE_YN"]          = "Y";
        requestData.sendData["BRD_RMK_NO"]      = this.brdRmkNo;
        requestData.sendData["USER_ID"]         = this.txtBoxUserV;
        requestData.sendData["PROC_ID"]         = this.txtBoxUserV;
        requestData.sendData["WRTR_DRPT_CD"]    = "x";
        requestData.sendData["ACCESS_IP"]       = this.$store.getters["userStore/GE_USER_ROLE"].cnnctIp;
        requestData.sendData["DATA_FLAG"]       = this.flagData.inputFlag;
        requestData.sendData["FILE_GROUP_KEY"]  = this.FILE_GROUP_KEY;
        requestData.sendData["BRD_QUICK_TY"]    = this.BRD_NEWQKTY;
        requestData.sendData["COMPLETE_YN"]    = "N";

        if(requestData.sendData["BRD_RMK"].length > 65535){
        this.common_alert(`내용은 65,535Byte를 초과할 수 없습니다.\n현재 ${requestData.sendData["BRD_RMK"].length}Byte 입력하셨습니다.`, "noti");
        return;
      }

        this.clickBtnSaveThen(requestData);
      },

      // 데이터 저장 callBack
      async clickBtnSaveThen(requestData) {
        let response = await this.common_postCall(requestData);
        let resHeader = response.HEADER;
        let resData = response.DATA;

        if(resHeader.ERROR_FLAG==false) {
          this.common_alert("게시물이 등록 또는 수정되었습니다.", "done");
          this.popupClose();
          return;
        } else {
          this.common_alert(this.alertMsg.responseError, 'error')
          return;
        }
        //this.$emit('submit');
      },


      //*********************************************
      //    수정 Btn 으로 팝업 오픈 시 상세정보 가저오기
      //*********************************************
      async getBrdDetail() {
        let requestData = {
          headers: {},
          sendData: {}
        }

        requestData.headers["URL"]     = "/api/board/detail-popup/selectNo-noChange/inqire";
        requestData.headers["SERVICE"] = "board.detail-popup.selectNo-noChange";
        requestData.headers["METHOD"]  = "inqire";
        requestData.headers["ASYNC"]   = false;
        requestData.headers["TYPE"]    = "BIZ_SERVICE";

        requestData.sendData["BRD_ID"]          = this.param.item.BRD_ID;
        requestData.sendData["BRD_NO"]          = this.param.item.BRD_NO;
        requestData.sendData["ASP_NEWCUST_KEY"] = "001";

        let response = await this.common_postCall(requestData);
        this.getBrdDetailThen(response);

        requestData = {};
      },

      //**********************************
      //     상세정보 가져오기 callBack
      //**********************************
      async getBrdDetailThen(response) {
        let resHeader = response.HEADER;
        let resData = response.DATA;

        if(resHeader.ERROR_FLAG==true) {
          this.common_alert(this.alertMsg.responseError, 'error')
        }
        console.log(`getBrdDetailThen resData : ${JSON.stringify(resData)}`)
        //this.txtBoxbizV   = this.param.item.ASP_NEWCUST_KEY;
        this.txtBoxbizV   = "001";
        //this.txtBoxUserV  = this.param.item.FST_USER_NM;
        console.log(this.param.userId);

        this.txtBoxUserV = this.param.userId;
        console.log(this.txtBoxUserV);
        this.txtBoxTitleV = this.restoreXSS_CKeditor(resData.at().BRD_TIT);
        this.txtAreaV     = this.restoreXSS_CKeditor(resData.at().BRD_RMK);

        if(resData.BULTN_FR_DT != '' ){
          this.dates[0] = resData[0].BULTN_FR_DT.replace(/\//g , '-');
          this.from = resData[0].BULTN_FR_DT.replace(/\//g , '-');
        }
        if(resData.BULTN_TO_DT != '' ){
          this.dates[1] = resData[0].BULTN_TO_DT.replace(/\//g , '-');
          this.to = resData[0].BULTN_TO_DT.replace(/\//g , '-');
        }
        

        this.FILE_GROUP_KEY = resData[0].FILE_GROUP_KEY;
        let resList = await this.mixin_getFileList(resData[0].FILE_GROUP_KEY);
        this.setFileList(resList)
      },

      //*********************************
      //        첨부파일 업로드
      //*********************************
      // 파일첨부 버튼 클릭
      clickAddFiles() {
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage

        this.dropZoneParam = {
          //ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY,
          ASP_NEWCUST_KEY : "001",
          fileGroupKey : this.FILE_GROUP_KEY,
          pathType : pathType, //publics,files,images,videos,inqrytypeimage
          busiType : busiType, //palette, phone, chat, km, bbs
          acceptedFiles : ".png,.jpg,.gif,.jpeg,.doc,.docx,.pdf,.csv,.xls,.xlsx,.ppt,.pptx",
          userOptions :{
            parallelUploads: 10,
            //url : process.env.VUE_APP_API_URL + "api/file/"+ busiType +"/"+ pathType +"/uploads",
            //url : "http://local.hkpalette.com:6001/" + "api/file/"+ busiType +"/"+ pathType +"/uploads",
          }
        };
        this.showDialog('VueDropzone');
      },

      // 첨부파일 팝업 업로드 후
      async uploadFileResponse(data){
        this.hideDialog('VueDropzone');
        this.FILE_GROUP_KEY = data[0].fileGroupKey;

        let resList = await this.mixin_getFileList(data[0].fileGroupKey);
        this.setFileList(resList)
      },

      //*********************************
      //        첨부파일 다운로드
      //*********************************
      async fileDownload(item){
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage

        await this.mixin_fileDownload(item, busiType, pathType)
      },

      //*********************************
      //          첨부파일 삭제
      //*********************************,
      clickDelFiles(){
        console.log(this.gridListSelected);
        if(this.gridListSelected.length != 0) {
          this.common_confirm('선택한 파일을 삭제하시겠습니까?',
              this.confirmDelYes, null, this.confirmDelNo, null, 'error');
        }
      },

      async confirmDelYes() {
        let rtnBln = await this.deleteFilesMulti(this.gridListSelected);

        if (rtnBln) {
          // 첨부파일 dataList 호출
          let resList = await this.mixin_getFileList(this.gridListSelected[0].FILE_GROUP_KEY);
          this.setFileList(resList)

          this.gridListSelected = [];
        }
      },

      confirmDelNo() {
        return;
      },

      //*********************************
      //        첨부파일 리스트 set
      //*********************************,
      setFileList(listData) {
        this.gridDataText = [];

        for(let i in listData) {
          let tempItem = listData[i];
          tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
          tempItem["index"] = ++i;

          this.gridDataText.push(tempItem);
        }
      },

      //***********************************************
      //    신규 Btn 으로 팝업창 오픈시 brdNo 받기
      //***********************************************
      async getBrdNo() {
        let requestData = {
          headers: {},
          sendData: {}
        };

        requestData.headers["URL"]     = "/api/innb/common/generate-unique-no/inqry";
        requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
        requestData.headers["METHOD"]  = "inqry";
        requestData.headers["ASYNC"]   = "false";
        requestData.headers["TYPE"]    = "BIZ_SERVICE";

        requestData.sendData["BIZ_CASE"] = "BRD";

        let response = await this.common_postCall(requestData);
        this.brdNo = response.DATA.at(0).RET_VAL;
      },

      //***************************************
      //       게시글 저장용 brdRmkNo 받기
      //***************************************
      async getBrdRmkNo() {
        let requestData = {
          headers: {},
          sendData: {}
        };

        requestData.headers["URL"]     = "/api/innb/common/generate-unique-no/inqry";
        requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
        requestData.headers["METHOD"]  = "inqry";
        requestData.headers["ASYNC"]   = "false";
        requestData.headers["TYPE"]    = "BIZ_SERVICE";

        requestData.sendData["BIZ_CASE"] = "TWB";
        let response = await this.common_postCall(requestData);
        this.brdRmkNo = response.DATA.at(0).RET_VAL;

        await this.setSaveData();
      },

      //*************************************
      //        팝업창 오픈 시 유형 체크
      //*************************************
      checkFlag(flag) {
        switch (flag) {
          case 'new':
            this.flagData.inputFlag = 'I';
            this.flagData.replyYn   = '0';
            this.flagData.lvlNo     = '-1';
            this.flagData.upperBrdNo= '-1';
            this.getBrdNo();
            break;

          case 'modify':
            this.flagData.inputFlag = 'U';
            this.flagData.replyYn   = '0';
            this.flagData.lvlNo     = '-1';
            this.flagData.upperBrdNo= '-1';
            this.brdNo = this.param.item.BRD_NO;
            this.brdId = this.param.item.BRD_ID;
            this.fixedFlag = (this.param.item.BRD_ID == '2')? false : true;
            this.getBrdDetail();
            break;

          default:
            break;
        }
      },

      //*********************************
      //           etc method
      //*********************************,
      showDialog(menu) {
        this[`dialog${menu}`] = true;
      },
      hideDialog(menu) {
        this[`dialog${menu}`] = false;
      },

      popupClose() {
        this.$emit('hide')
      },

      fromOnInput: function() {
        // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
        if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
          // 종료일자보다 이후를 입력했으면 종료일자로 변경
          if (moment(this.from).isAfter(this.dates[1])) {
            this.$nextTick(() => {
              this.from = this.dates[1];
            });
          }

          this.$nextTick(() => {
            this.dates[0] = this.from;
            // datepick 다시 랜더링
            this.menuStartDate = false;
            this.menuStartDate = true;
          });
        }
      },
      startDate(e) {
        this.from = e;
      },
      toOnInput: function() {
        // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
        if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
          // 시작일자보다 이전을 입력했으면 시작일자로 변경
          if (moment(this.to).isBefore(this.dates[0])) {
            this.$nextTick(() => {
              this.to = this.dates[0];
            });
          }
          
          this.$nextTick(() => {
            this.dates[1] = this.to;
            // datepick 다시 랜더링
            this.menuEndDate = false;
            this.menuEndDate = true;
          });
        }
      },
      endDate(e) {
        this.to = e;
      },

    },

    created() {
      console.log(`E050202P02Param call - param : ${JSON.stringify(this.param)}`)
    },

    async mounted() {
      this.txtBoxBizV  = this.param.bizNm;
      this.txtBoxUserV = this.param.userId;
      this.txtBoxUserV_show = this.user_name + "("+this.param.userId+")";
      this.ASP_NEWCUST_KEY = "001";

      this.checkFlag(this.param.flag);

      let codeName = ["BRD001"];
      let common_code = await this.mixin_common_code_get_all(codeName);
      this.BRD_QKTY = [...this.BRD_QKTY, ...common_code[0].group_value];
    },

    computed: {
      dateRangeText() {
        let newStartDate = this.dates[0].replaceAll('-', "/");
        let newEndDate = this.dates[1].replaceAll('-', "/");;
        return newStartDate + " ~ " + newEndDate;
      },

      ...mapGetters({
        user_name: "userStore/GE_USER_NAME",
        user_id: "userStore/GE_USER_ID",
        atrtGroupNm: "userStore/GE_USER_AUTHOR_GROUP_NM",
        ATTR_DIV_NM_A: "userStore/GE_USER_ATTR_DIV_NM_A",
        ATTR_DIV_NM_B: "userStore/GE_USER_ATTR_DIV_NM_B",
      }),
    },
  };
</script>

<style></style>
